import './App.css';

import Select, { StylesConfig } from 'react-select';
import { useState } from 'react';

interface Sede {
  city: string;
  phone: string,
  address: string
}

function App() {

  const sedes: Sede[] = [
    {
      city: "APARTADÓ",
      phone: '604 6040086',
      address: "CL 98 # 104-28  Barrio Ortiz"
    },
    {
      city: "ARMENIA",
      phone: '606 7358227',
      address: "Carrera 13 A No.1 A - 117 Oficina 403 Edificio López - Parque Fundadores"
    },
    {
      city: "BARRANQUILLA",
      phone: '605 3091858',
      address: "Carrera 51# 84-187 Barrio San Vicente"
    },
    {
      city: "BOGOTÁ",
      phone: '601 7451415',
      address: "Ac. 26 #85D - 55. Módulo 1 Oficina 102"
    },
    {
      city: "BUCARAMANGA",
      phone: '607 6970001',
      address: "Calle 55 N # 31-83 Barrio Antiguo Campestre"
    },
    {
      city: "CALI",
      phone: '602 4863235',
      address: "Avenida 3 Norte # 17-69 Barrio Versalles"
    },
    {
      city: "CARTAGENA",
      phone: '605 6930828',
      address: "Carrera 17 No. 24 - 178 Primer Piso Barrio Manga"
    },
    {
      city: "CUCUTA",
      phone: '607 5942612',
      address: "Calle  13 A # 2 E -47  Barrio caobos"
    },
    {
      city: "FLORENCIA",
      phone: '608 4380224',
      address: "Carrera 11 Nº 5B-75 Local. 104. Barrio. Juan XXIII"
    },
    {
      city: "GARZÓN",
      phone: '608 8630775',
      address: "Calle 3 # 4b - 35"
    },
    {
      city: "IBAGUE",
      phone: '608 2770816',
      address: "Calle 30 # 5 - 21 Piso 2. Barrio Belalcázar"
    },
    {
      city: "MANIZALES",
      phone: '606 8956994',
      address: "Carrera  25 # 52-30  Edificio Versalles Plaza Local 2."
    },
    {
      city: "MEDELLÍN",
      phone: '604 6045515',
      address: "Carrera 43 A # 31- 129  Barrio San Diego"
    },
    {
      city: "NEIVA",
      phone: '608 8632446',
      address: "Calle 46 # 16 -18, local 301, Centro Comercial San Juan Plaza"
    },
    {
      city: "PASTO",
      phone: '602 7382070',
      address: "Carrera 32 # 16A -31/35 Barrio Mariadiaz"
    },
    {
      city: "PEREIRA",
      phone: '606 3402314',
      address: "Calle  22 #  9 - 63 Piso 1 Barrio Centro"
    },
    {
      city: "PITALITO",
      phone: '608 8630774',
      address: "CALLE 17 # 6 - 17 Café Mall piso - 2"
    },
    {
      city: "POPAYÁN",
      phone: '602 8368212',
      address: "Carrera 8 No. 21N - 25 Barrio Ciudad Jardin"
    },
    {
      city: "SANTA MARTA",
      phone: '605 4351910',
      address: "Av. Libertador No. 25 -99"
    },
    {
      city: "TUNJA",
      phone: '608 7470848',
      address: "Avenida Norte  No.47A -40 Oficina 15 y16 Centro Comercial Centro Norte - 3er Piso"
    },
    {
      city: "VALLEDUPAR",
      phone: '605 5898476',
      address: "Calle 16 # 19 B - 56. Local L3 - 09. CC. Megamall"
    },
    {
      city: "VILLAVICENCIO",
      phone: '608 6784235',
      address: "Carrera 40 # 26C- 47 Barrio 7 de agosto"
    },
    {
      city: "YOPAL",
      phone: '608 6333559',
      address: "Calle 30 # 26 - 29 Piso 2. CC. Alcaraván"
    },
    {
      city: "LA DORADA, CALDAS",
      phone: '',
      address: "Calle 14 # 2 - 23"
    }
  ];

  const cityStyles: StylesConfig = {
    control: (base, state) => {
      return {
        ...base,
        border: 'none',
        borderBottom: '1px solid #21406d',
        boxShadow: 'none',
        '&:hover': {
          border: 'none',
          borderBottom: '1px solid #21406d',
        },
        borderRadius: '0',
        background: 'white'
      }
    },
    indicatorSeparator: (base, state) => {
      return {
        ...base,
        display: 'none'
      }
    },
    input: (base, state) => {
      return {
        ...base,
        color: '#21406d'
      }
    },
    singleValue: (base, state) => {
      return {
        ...base,
        color: '#21406d',
        fontWeight: '600'
      }
    },
    placeholder: (base, state) => {
      return {
        ...base,
        color: '#21406d',
        fontWeight: '600'
      }
    },
  };

  const customStyles: StylesConfig = {
    control: (base, state) => {
      return {
        ...base,
        border: 'none',
        borderBottom: '1px solid #21406d',
        boxShadow: 'none',
        '&:hover': {
          border: 'none',
          borderBottom: '1px solid #21406d',
        },
        borderRadius: '0',
        background: 'white'
      }
    },
    indicatorSeparator: (base, state) => {
      return {
        ...base,
        display: 'none'
      }
    },
    singleValue: (base, state) => {
      return {
        ...base,
        color: '#21406d',
        fontWeight: '600'
      }
    },
    placeholder: (base, state) => {
      return {
        ...base,
        display: 'none',
      }
    },
    indicatorsContainer: (base, state) => {
      return {
        ...base,
        display: 'none',
      }
    },
  };

  const [selectedCity, setSelectedCity] = useState<any>(null);
  const [selectedPhone, setSelectedPhone] = useState<any>(null);
  const [selectedAddress, setSelectedAddress] = useState<any>(null);

  const handleCityChange = (selectedOption: any, field: 'city' | 'phone' | 'address') => {
    const selected = sedes.find(x => x[field] === selectedOption.value);

    if (selected) {
      setSelectedCity({
        value: selected.city,
        label: selected.city
      });
      setSelectedPhone({
        value: selected.phone,
        label: selected.phone
      });
      setSelectedAddress({
        value: selected.address,
        label: selected.address
      });
    }
  };

  const handleMenuOpen = () => {
    setSelectedCity(null);
    setSelectedPhone(null);
    setSelectedAddress(null);
  };

  return (
    <div className="ser-select-sede-body">

      <div className="backgound"></div>

      <div className="content">

        <div className='title'>Selecciona la ciudad para conocer el número de contacto:</div>

        <div className="form-group">

          <div className="label">Ciudad:</div>

          <Select
            placeholder='Selecciona la ciudad'
            value={selectedCity}
            onChange={(newValue) => {
              handleCityChange(newValue, 'city');
            }}
            onMenuOpen={handleMenuOpen}
            options={
              sedes.map(x => ({
                value: x.city,
                label: x.city
              }))
            }
            styles={cityStyles}
          />

        </div>

        <div className="form-group">

          <div className="label">Número de contacto:</div>

          <Select
            value={selectedPhone}
            onChange={(newValue) => {
              handleCityChange(newValue, 'phone');
            }}
            options={sedes.map(x => ({
              value: x.phone,
              label: x.phone
            }))}
            styles={customStyles}
            isDisabled={true}
            />

        </div>

        <div className="form-group">

          <div className="label">Dirección:</div>

          <Select
            value={selectedAddress}
            onChange={(newValue) => {
              handleCityChange(newValue, 'address');
            }}
            options={sedes.map(x => ({
              value: x.address,
              label: x.address
            }))}
            styles={customStyles}
            isDisabled={true}
          />

        </div>


      </div>

    </div>
  );
}

export default App;
